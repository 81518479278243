<template>
  <div class="admin-menu tabs-menu widget">
    <label class="label">
      {{ $t("common.menu") }}
    </label>

    <v-tabs vertical class="card py-4">
      <template v-for="item in items">
        <v-tab
          v-if="!item.hidden"
          :key="item.name"
          :to="item.route || null"
          :disabled="item.disabled"
        >
          <v-icon color="white" size="26">
            {{ item.icon }}
          </v-icon>
          {{ item.name }}
        </v-tab>
      </template>
    </v-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          name: this.$t("common.dashboard"),
          icon: "mdi-view-dashboard",
          route: { name: "admin" },
        },
        {
          name: this.$t("common.users"),
          icon: "mdi-account-multiple",
          route: { name: "admin.users" },
        },
        {
          name: this.$t("reports.reports"),
          icon: "mdi-flag",
          route: { name: "admin.reports" },
        },
        {
          name: this.$t("hashtags.hashtags"),
          icon: "mdi-pound",
          route: { name: "admin.hashtags" },
        },
        {
          name: this.$t("survey.betaTesting"),
          icon: "mdi-clipboard-text",
          route: { name: "admin.survey" },
        },
      ],
    };
  },
};
</script>
