<template>
  <div
    class="stat-box secondary d-flex flex-column rounded-lg pa-4"
    style="width: 150px; height: 130px"
  >
    <spinner v-if="loading" center />

    <template v-else>
      <div
        class="number primary--text font-weight-bold h-50 d-flex align-center"
        :style="{ 'font-size': small ? '25px' : '32px' }"
      >
        {{ value }}
      </div>

      <div
        class="label font-weight-medium h-50"
        :class="{ 'font-size-14': small }"
      >
        {{ label }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: [Number, String],
    label: String,
    loading: Boolean,
    small: Boolean,
  },
};
</script>

<style></style>
